<template>
    <BaseContainer hideMenu="true">
        <span slot="principal">
            <v-container fluid style="background-color: #485461;
                background-image: linear-gradient(315deg, #485461 0%, #28313b 74%);">
                <v-row align="center" justify="center">
                    <v-col cols="12" sm="8" md="4">
                        <v-card class="elevation-12">
                            <v-toolbar dark>
                                <v-spacer/>
                                CADASTRAR NOVA SENHA
                                <v-spacer/>
                            </v-toolbar>
                            <v-card-text>
                                <!-- <fieldset style="padding: 5px; border-radius: 5px;" class="mt-2">
                                    <legend>Informe o código recebido no seu e-mail</legend>
                                    <v-otp-input
                                        v-model="token"
                                        length="6"
                                    />
                                </fieldset> -->
                                <v-text-field
                                    label="Nova senha"
                                    ref="password"
                                    v-model="password"
                                    prepend-icon="lock"
                                    class="mt-2"
                                    autocomplete="current-password"
                                    :type="showPassword ? 'text' : 'password'"
                                    @click:append="showPassword = !showPassword"
                                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                />
                                <v-text-field
                                    label="Confirmar nova senha"
                                    ref="password"
                                    v-model="passwordConfirm"
                                    prepend-icon="lock"
                                    @keypress.enter="confirmar"
                                    autocomplete="current-password"
                                    :type="showPasswordConfirm ? 'text' : 'password'"
                                    @click:append="showPasswordConfirm = !showPasswordConfirm"
                                    :append-icon="showPasswordConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                                />
                            </v-card-text>
                            <v-card-actions>
                                <v-btn
                                    block
                                    dark
                                    large
                                    @click="confirmar"
                                    color="#e50914"
                                    :loading="loading"
                                    class="white--text"
                                >Confirmar</v-btn>
                            </v-card-actions>
                            <v-card-actions>
                                <v-btn
                                    block
                                    text
                                    small
                                    @click="goToLogin"
                                    color="#e50914"
                                >Voltar ao login</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </span>
    </BaseContainer>
</template>

<script>
import BaseContainer from '@/components/BaseContainer'

export default {
    name: 'NewPassword',

    components: {
        BaseContainer,
    },

    data: () => ({
        loading: false,
        password: '',
        passwordConfirm: '',
        showPassword: false,
        showPasswordConfirm: false,
    }),

    computed: {
        token() {
            return decodeURIComponent(this.$route.query.token || '');
        },
    },

    methods: {
        confirmar() {
            if (this.token.length < 6) {
                this.notify('Código inválido', 'warning');
                return;
            }

            if (this.password.length < 8) {
                this.notify('Senha deve ter ao menos 8 digitos', 'warning');
                return;
            }

            if (this.password != this.passwordConfirm) {
                this.notify('A senha não confere', 'warning');
                return;
            }

            this.loading = true;
            this.$http.post('reset-password', {
                token: this.token,
                email: this.getEmail,
                password: this.password,
                passwordConfirm: this.passwordConfirm,
            }).then(async (resp) => {
                const data = resp.data;

                if (data.type == 'warning') {
                    this.notify(data.msg, 'warning');
                    return;
                }

                this.notify(data.msg);

                this.$router.push('login');
            })
            .catch(() => (this.notify('Não foi possivel realizar login', 'warning')))
            .finally(() => (this.loading = false))
        },

        goToLogin() {
            this.$router.push('login');
        },
    },
}
</script>

<style scoped>
.container  {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.container .row {
    margin: 0 auto;
}
</style>
